.normal16 {
  font-size: 16px;
}

.bold16 {
  font-weight: 700;
  font-size: 16px;
}

.size20 {
  font-size: 20px;
}

.bold16animation {
  font-weight: 700;
  font-size: 16px;
  animation: change 10s step-end both;
}

.accountSummariesTables .ant-table-thead > tr > th,
.accountSummariesTables .ant-table-tbody > tr > td,
.accountSummariesTables .ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px 8px;
}

.accountSummariesTables .ant-table-tbody .tradeSummaryTable tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td
{
  /* 192e45 */
  border-width: 12px;
  border-color: #1d1d1d;
  background-color: #177ddc3f;
  transition: background-color 0.3s;
}
.accountSummariesTables .ant-table-tbody .tradeSummaryTable tr.ant-table-expanded-row.ant-table-expanded-row-level-1 > td:hover{
  background-color: #262626;
}


.orderQueuesTables .pageSelector {
  padding-bottom: 12px;
  text-align-last: end;
}

.orderQueuesTables .ant-table-thead > tr > th,
.orderQueuesTables .ant-table-tbody > tr > td,
.orderQueuesTables .ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px 5px;
}

.orderQueuesTables .anticon-exclamation-circle {
  color: red;
}

.orderQueuesTables .pageSelector .ant-btn {
  margin-right: 4px;
  margin-left: 4px;
  padding: 0px 10px;
}

.orderQueuesTables .pageSelector .active {
  color: rgb(23, 125, 220);
  border-color: rgb(23, 125, 220);
}

.orderQueuesTables .end-section > td {
  border-bottom-width: 2px;
  border-bottom-color: red;
}

.orderQueuesTables .start-section > td {
  border-top-width: 2px;
  border-top-color: red;
}

@keyframes change {
  to {
    color: gray;
  }
}

.transparent {
  background: transparent;
}

.black {
  background: black;
}
