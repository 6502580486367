*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #070707;
}

#root {
  height: 100%;
  width: 100%;
}

/* Optional: Ant Design adjustments for Layout */
.ant-layout {
  margin: 0;
  padding: 0;
}